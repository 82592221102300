// plugins/training/src/apiClient.ts

import { trainingApiRef, TrainingApi } from './api';
import { Config } from '@backstage/config';
import { DiscoveryApi, FetchApi, IdentityApi } from '@backstage/core-plugin-api';

export class TrainingClient implements TrainingApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;
  private readonly identityApi: IdentityApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi; identityApi: IdentityApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
    this.identityApi = options.identityApi;
  }

  private async request<T>(path: string, options: RequestInit = {}): Promise<T> {
    const baseUrl = await this.discoveryApi.getBaseUrl('training');
    const url = `${baseUrl}${path}`;
    const response = await this.fetchApi.fetch(url, options);

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to fetch ${path}: ${response.status} ${errorText}`);
    }

    return response.json();
  }

  async getModules(): Promise<TrainingModule[]> {
    return this.request<TrainingModule[]>('/modules');
  }

  async getModule(id: string): Promise<TrainingModule> {
    return this.request<TrainingModule>(`/modules/${id}`);
  }

  async createModule(module: Omit<TrainingModule, 'id' | 'created_at' | 'updated_at'>): Promise<TrainingModule> {
    return this.request<TrainingModule>('/modules', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(module),
    });
  }

  async updateModule(id: string, module: Partial<Omit<TrainingModule, 'id' | 'created_at' | 'updated_at'>>): Promise<TrainingModule> {
    return this.request<TrainingModule>(`/modules/${id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(module),
    });
  }

  async deleteModule(id: string): Promise<void> {
    await this.request<void>(`/modules/${id}`, { method: 'DELETE' });
  }

  async getUsers(): Promise<string[]> {
    return this.request<string[]>('/users');
  }
}

export const createTrainingApi = (discoveryApi: DiscoveryApi, fetchApi: FetchApi, identityApi: IdentityApi): TrainingApi => {
  return new TrainingClient({ discoveryApi, fetchApi, identityApi });
};
